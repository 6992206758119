
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Toast } from "vant";
import { ReportService, WechatService, UserService } from "src/services";
import pageBack from "src/components/page-back";
import router from "src/router";
import { nextTick } from "process";
import globalConfig from "src/config/index";
// import sensors from "../../sensors"
import { tools } from "src/utils";

@Component({
  components: {
    pageBack,
  },
})
export default class ReportDecodeList extends Vue {
  protected isWeatchMini = true;

  /**
   * 共有多少份未授权报告
   * @protected
   * @returns Array<any>
   */
  public selfUnauthReportNum: number | string = 0;

  /**
   * 跳转小程序封装
   * @protected
   * @returns void
   */
  private async wx_launch(info): Promise<void> {
    if (!(await tools.isWechat())) {
      return;
    }
    var btn = document.getElementById(info.eleId); //获取元素
    if (!btn) {
      return;
    }
    let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
    script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
    script.text = info.content; // 自定义的html字符串内容
    let html = `<wx-open-launch-weapp style="width:100%; height:100%;display:block;overflow: hidden;" username="${info.appid}" path="${info.url}">${script.outerHTML}</wx-open-launch-weapp>`;
    btn.innerHTML = html; // html字符串赋值
    // 点击按钮 正常跳转触发
    btn.addEventListener("launch", function (e) {
      console.log("success");
    });
    // 点击跳转 抛出异常
    btn.addEventListener("error", function (e) {
      console.log("fail", e);
      alert(`跳转异常 - ${JSON.stringify(e)}`);
    });
  }

  /**
   * 初始化jssdk
   * @private
   * @returns Promise<any>
   */
  private async init(): Promise<any> {
    if (await tools.isWechat()) {
      let url = location.href;
      let { content: result } = await WechatService.instance.getWechatJSSDK(url);
      if (result.data) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.data.appId, // 必填，公众号的唯一标识
          timestamp: result.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
          signature: result.data.signature, // 必填，签名
          jsApiList: ["openLocation"],
          openTagList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {});
      }
    }
  }

  private reportContent: string = "";
  private cancelParams = {};

  /**
   * dom加载完
   * @protected
   * @returns void
   */
  protected mounted() {
    this.init();
    this.reportContent = `
            <button class="test-btn"></button>
            <style>
                .test-btn{
                    width: 100%;
                    height: 520px;
                    background: transparent;
                    border: none;
                    color:rgba(255,255,255,0)
                }
                .test-btn:focus{outline: 0}
            </style>
            `;
  }

  /**
   * 跳转详情
   */
  protected async initOCRAnalysis(memberId) {
    try {
      let { content: res } = await ReportService.instance.initOCRAnalysis(memberId);
    } catch (error) {}
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 跳转详情
   */
  protected onAgree(): any {
    localStorage.removeItem("status");
    router.push({ name: "reportAppointmentDetail", query: { id: this.reportId } });
    this.showOverlay = false;
  }

  /**
   * 解析完成弹框
   * @private
   * @returns boolean
   */
  private showOverlay: boolean = false;

  /**
   * 解析失败弹框
   * @private
   * @returns boolean
   */
  private parsingFailed: boolean = false;

  /**
   * 提示图片不可继续上传
   * @private
   * @returns boolean
   */
  private interpretOverlay: boolean = false;

  /**
   * 正在解析
   * @private
   * @returns boolean
   */
  private showAnalysis: boolean = false;

  /**
   * 列表数据
   * @protected
   * @returns Array<any>
   */
  protected dataList: Array<any> = [];

  protected reportId = null;

  /**
   * 家属报告数量
   * @protected
   * @returns Array<any>
   */
  protected familyUnReadReportNum: number | string = 0;

  /**
   * 共有多少份报告
   * @protected
   * @returns Array<any>
   */
  protected selfTotalReportNum: number | string = 0;

  /**
   * 报告类型
   * @protected
   * @returns Array<any>
   */
  protected reportNoType: number | string = 0;

  /**
   * 跳转页面
   * @private
   * @returns void
   */
  private onGopage(name: string): void {
    this.$router.push({ name });
  }

  private laterView() {
    localStorage.removeItem("status");
    this.showOverlay = false;
  }

  private inspect() {
    localStorage.removeItem("status");
    this.parsingFailed = false;
  }

  /**
   * 选择人员
   * @protected
   * @returns boolean
   */
  protected showUserPopup: boolean = false;

  /**
   * 获取用户列表
   * @protected
   * @returns Array<any>
   */
  protected userList: Array<any> = [];

  /**
   * 当前选中用户
   * @protected
   * @returns string
   */
  protected currentUser: any = {};

  /**
   * 当前用户成员map
   * @protected
   * @returns string
   */
  protected memberTypeMap: any = {
    self: 1,
    member: 2,
  };

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  protected async created() {
    if (!(await tools.isWeatchMini())) {
      this.isWeatchMini = false;
    }
    this.getList();
  }

  @Watch("$route", { deep: true, immediate: true })
  protected async updateOpen(to: any) {
    // 判断用户是否再次进入页面查看
    if (localStorage.getItem("status") == "1") {
      this.showOverlay = true;
      localStorage.removeItem("status");
    } else if (localStorage.getItem("status") == "2") {
      this.parsingFailed = true;
      localStorage.removeItem("status");
    }

    let formData = this.$store.state.uploadParams;
    if (!formData) {
      return;
    }

    if (formData.reportId) {
      this.reportId = formData.reportId;
    }

    this.getList();

    let timeId = null;

    if (formData.reportFileType == "1" && this.lastRouter == "reportAppointmentUpload") {
      try {
        // 展示解析中的按钮
        // let {content:res} = await ReportService.instance.uploadReport(formData);

        this.$store.dispatch("setLastRouter", null);
        if (formData.supplierBrandId == 9999) {
          Toast("上传成功");
          return;
        } else {
          this.showAnalysis = true;
          Toast("上传成功,正在解析");
        }

        // this.reportId = res.data

        clearInterval(timeId);
        timeId = setInterval(async () => {
          try {
            let { content: res } = await ReportService.instance.getAnalysisResults(
              this.reportId,
              this.currentUser.memberId
            );
            localStorage.setItem("status", res.data?.status);
            if (res.data?.status == 1) {
              clearInterval(timeId);
              // 关闭解析中的按钮
              this.showAnalysis = false;
              // 展示弹框
              this.showOverlay = true;
              Toast("解析成功");
            } else if (res.data?.status == 2) {
              this.showAnalysis = false;
              this.getList();
              // 不展示弹框
              this.showOverlay = false;
              clearInterval(timeId);
              this.parsingFailed = true;
            }
          } catch (err) {
            Toast("解析失败");
            this.parsingFailed = true;
            this.showAnalysis = false;
            clearInterval(timeId);
          }
        }, 5000);
      } catch (error) {
        // this.parsingFailed = true
        this.showAnalysis = false;
        this.getList();
        clearInterval(timeId);
      }
    }
  }

  /**
   * 获取用户列表
   * @private
   * @returns void
   */
  protected getUserList(userList: any): void {
    this.userList = userList;
    let data = userList[0];
    if (!this.currentUser.employerMemberId) {
      this.$nextTick(() => {
        this.getList();
      });
    }

    this.currentUser = {
      employerMemberId: data.employerMemberId,
      memberType: data.memberType,
      familyType: data.familyType,
      phone: data.phone,
      name: data.memberName,
      cardNo: data.cardNo,
      sex: data.sex,
      marriageStatus: data.marriageStatus,
      marriageStatusStr: data.marriageStatusStr,
      memberTypeStr: data.memberTypeStr,
      sexStr: data.sexStr,
      age: data.age,
      memberId: data.memberId,
    };
    // this.getList();
    this.initOCRAnalysis(this.currentUser.memberId);
  }

  /**
   * 确认切换用户
   * @private
   * @returns void
   */
  protected onConfirmUser(data: any): void {
    this.currentUser = {
      employerMemberId: data.employerMemberId,
      memberType: data.memberType,
      familyType: data.familyType,
      phone: data.phone,
      name: data.memberName,
      cardNo: data.cardNo,
      sex: data.sex,
      marriageStatus: data.marriageStatus,
      marriageStatusStr: data.marriageStatusStr,
      memberTypeStr: data.memberTypeStr,
      sexStr: data.sexStr,
      age: data.age,
      memberId: data.memberId,
    };
    this.showUserPopup = false;
    this.getList();
  }

  /**
   * 获取列表
   */
  protected async getList(): Promise<any> {
    try {
      let { content: result } = await ReportService.instance.getPhysicalReportList({
        employerMemberId: this.currentUser.employerMemberId,
      });
      if (result.data) {
        this.dataList = result.data.reportGroups;
        try {
          let { content: result1 } = await UserService.instance.jump2Personal();
          if (result1.data) {
            this.dataList.forEach((dataItem) => {
              dataItem.reports.forEach((item) => {
                nextTick(() => {
                  let cancelParams = {
                    appid: "gh_387fc8fa08dd",
                    eleId: item.id, // 元素id
                    url: `/pages/health-records/index?k=${result1.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/decode?id=${item.id}`,
                    content: this.reportContent, // 自定义的html内容
                  };

                  this.wx_launch(cancelParams);
                });
              });
            });
          }
        } catch (err) {
          Toast(JSON.stringify(err));
        }
        this.familyUnReadReportNum = result.data.familyUnReadReportNum;
        this.selfTotalReportNum = result.data.selfTotalReportNum;
        this.selfUnauthReportNum = result.data.selfUnauthReportNum;
        this.reportNoType = result.data.reportNoType;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return JSON.parse(localStorage.getItem("userInfo"));
  }

  /**
   * 公司信息
   * @private
   * @returns void
   */
  private get orgInfo(): any {
    return this.$store.getters.orgInfo || {};
  }

  /**
   * vuex记录的前一个页面的路由
   * @private
   * @returns void
   */
  private get lastRouter(): any {
    return this.$store.getters.getLastRouter || null;
  }

  /**
   * 跳转报告上传
   */
  protected onActionUpLoad(): any {
    router.push({
      name: "reportAppointmentUpload",
      query: { id: this.currentUser.employerMemberId, name: this.currentUser.name },
    });
  }

  /**
   * 查询报告
   */
  protected async onQueryReport() {
    // 确认客户是否已经验证过是本人
    let { content: result } = await ReportService.instance.checkAuth();
    if (result.data) {
      router.push({ name: "reportQuery" });
    } else {
      router.push({ name: "identityVerify" });
    }
    let data = {
      id: this.currentUser.employerMemberId,
      name: this.currentUser.name,
      phone: this.currentUser.phone,
    };
    this.$store.dispatch("setReportParams", data);
  }

  /**
   * 激活时
   */
  protected activated(): any {
    this.showUserPopup = false;
    if (this.currentUser.employerMemberId) {
      (this.$refs["uFamilyList"] as any).checkFamilyList();
    }
  }

  /**
   * 受检人管理-是否可编辑信息
   * @private
   * @returns void
   */
  private get isEditStatus(): any {
    return this.$store.getters.getIsEditStatus || true;
  }

  /**
   * 是否展示预约须知
   * @private
   * @returns void
   */
  private get isShowNotice(): any {
    return this.$store.getters.getIsShowNotice || false;
  }

  /**
   * 婚姻状态map
   * @private
   * @returns string
   */
  private marriageStatusMap: any = {
    marriaged: 0,
    unMarriage: 1,
  };

  /**
   * 性别map
   * @private
   * @returns string
   */
  private sexMap: any = {
    women: 0,
    men: 1,
  };

  /**
   * 跳转体检预约
   */
  protected onActionAppointment(): any {
    let { memberType, sex, marriageStatus } = this.currentUser;
    // 体检预约点击
    // if(memberType == this.memberTypeMap.self) {
    //     sensors.track('TJ_MEA_Click', {
    //         me_channel: '员工体检',
    //         current_page: "TJ-报告列表页",
    //     });
    // } else {
    //     sensors.track('TJ_MEA_Click', {
    //         me_channel: '家属体检',
    //         current_page: "TJ-报告列表页",
    //     });
    // }
    // 女性未婚 展示检前须知页
    if (
      this.isEditStatus &&
      this.isShowNotice &&
      sex == this.sexMap.women &&
      marriageStatus == this.marriageStatusMap.unMarriage
    ) {
      // 添加检前须知页
      this.$router.push({
        name: "physical-inspection",
        query: {
          employerMemberId: this.reportNoType == 4 || this.reportNoType == 1 ? this.currentUser.employerMemberId : "",
          type: this.reportNoType == 3 || this.reportNoType == 4 ? 2 + "" : 1 + "",
        },
      });
    } else {
      router.push({
        name: "physical-package",
        query: {
          employerMemberId: this.reportNoType == 4 || this.reportNoType == 1 ? this.currentUser.employerMemberId : "",
          type: this.reportNoType == 3 || this.reportNoType == 4 ? 2 + "" : 1 + "",
        },
      });
    }
  }

  /**
   * 获取详情
   */
  protected onDetail(item: any): any {
    router.push({ name: "reportAppointmentDetail", query: { id: item.id + "" } });
  }

  /**
   * 图片点击解读
   */
  protected async onShowPop(id): Promise<any> {
    this.interpretOverlay = true;
    try {
      let { content: result } = await UserService.instance.jump2Personal();
      if (result.data) {
        let cancelParams = {
          appid: "gh_387fc8fa08dd",
          eleId: "toReport", // 元素id
          url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/decode?id=${id}`,
          content: this.reportContent, // 自定义的html内容
        };

        this.wx_launch(cancelParams);
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }
}
